.transparent-carousel {
  background: transparent;
  /* padding: 50px; */
}

.carousel-item-bg {
  /* background-color: rgba(255, 255, 255, 0.2); */
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  gap: 50px;
  align-items: center;
  /* padding-top: 100px; */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  opacity: 0.7;
  width: 30px;
  height: 30px;
}

.carousel-control-prev-icon {
  transform: rotate(180deg);
}

.carousel-indicators {
  display: none;
}

.carousel-control-prev,
.carousel-control-next {
  width: 30px;
  height: 30px;
  z-index: 1;
  top: 29%;
  transform: translateY(-29%);
}
.carousel-control-prev {
  left: 30%;
  transform: translateX(-30%);
}
.carousel-control-next {
  right: 30%;
  transform: translateX(30%);
}

.leftcontentxs,
.rightcontentxs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.rightcontentxs {
  align-items: flex-start;
}
.leftcontentxs p:nth-child(1),
.rightcontentxs p:nth-child(1) {
  background: linear-gradient(to right, #49bc92, #49bc92);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 34px;
  font-weight: 600;
  padding-top: 100px;
}
.leftcontentxs p:nth-child(2),
.rightcontentxs p:nth-child(2) {
  font-size: 18px;
  color: #65676d;
  font-weight: 500;
}

@media screen and (max-width: 850px) {
  .carousel-item-bg {
    gap: 10px;
  }
}
