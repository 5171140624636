.swiper {
  width: 97%;
  height: 65vh;
}

.swiper-wrapper {
  width: 100% !important;
  /* height: 50vh; */
  display: flex;
  /* padding: 50px; */
  align-items: center;
  /* margin:  0 !important; */
}

.swiper-slide {
  height: auto !important;
  margin: 0 !important;
}
.testi-card {
  width: 25em !important;
  /* height: 90% !important; */
  border-radius: 0.5rem !important;
  background: #fff !important;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15) !important;
  padding: 30px;

  display: flex !important;
  /* align-items: center !important; */
  flex-direction: column !important;

  margin: 0 3em !important;
}

@media only screen and (min-width: 768px) {
  .swiper-slide-next {
    transition: scale 0.5s;
    transform: scale(1.1) !important;
    background: linear-gradient(135deg, #49bc92 0%, #49bc92 100%) !important;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15) !important;
    color: white !important;
    /* padding: 20px;
    t */
    /* transform: scale(1.5); */
  }

  .swiper-slide-next .card__content h5,
  .swiper-slide-next .card__content p {
    color: white !important;
  }
}

.card__image {
  display: flex;
  /* width: 3em; */
  /* height: 3em; */
  padding: 0.5rem;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  /* margin-bottom: 2em; */
}

.profileimg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.star-icon {
  font-size: 40px;
  margin: 0.1em;
}
.stars {
  width: 70%;
  object-fit: contain;
}

.card__content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.card__title {
  font-size: 1.5rem;
  font-weight: 500;
  position: relative;
  top: 0.2em;
}

.card__name {
  width: 100%;

  color: #49bc92;

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 40px; */
}

.card__text {
  /* text-align: center !important; */
  /* margin: 1em 0 !important; */

  color: #49bc92 !important;

  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 300 !important;
  /* line-height: 28.5px; */
}

.card__btn {
  background-color: var(--color);
  color: #fff;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  border: none;
  padding: 0.5em;
  border-radius: 0.5em;
  margin-top: 0.5em;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .testi-card {
    width: 80vw !important;
  }

  .card__name {
    font-size: 2rem;
  }

  .card__text {
    font-size: 1rem !important;
  }

  .swiper-slide-active {
    transform: scale(1.2);
    background: linear-gradient(135deg, #49bc92 0%, #49bc92 100%) !important;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15) !important;
    color: white !important;
  }

  .swiper-slide-active .card__content h5,
  .swiper-slide-active .card__content p {
    color: white !important;
  }
}

@media only screen and (max-width: 531px) {
  .testi-card {
    width: 75vw !important;
  }

  .star-icon {
    font-size: 18px;
    margin: 0.1em;
  }

  .card__name {
    font-size: 1.5rem;
    text-align: center;
  }

  .card__text {
    font-size: 0.9rem !important;
  }
}
@media only screen and (max-width: 426px) {
  .testi-card {
    width: 70vw !important;
  }
  .swiper {
    height: 55vh;
  }

  .card__name {
    font-size: 1.5rem;
  }

  .card__text {
    font-size: 0.8rem !important;
  }
}
