.card-container-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.card-about {
  border-radius: 20px;
  background: var(--white, #fcfcfc);
  box-shadow: -7.45471px 7.45471px 51.11803px 0px rgba(51, 51, 51, 0.12);
  width: 40%;
  /* height: 370px; */
  margin-top: -110px;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  font-size: 18px;
  border: none;
}

.right {
  align-self: flex-end;
}

.left {
  align-self: flex-start;
}
