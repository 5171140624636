.detail-container {
  width: 100%;
  display: flex;
  height: 100vh;
}
.detail-container .detail-content {
  width: 35%;
  background: url("../../../../assets/images/phone.png");
  padding: 0 40px;
  background-repeat: no-repeat;
  background-size: cover;
}

.book {
  height: 50px;
  width: 330px;
  background: linear-gradient(to right, #49bc92, #49bc92);
  border-radius: 5px;
  color: white;
  letter-spacing: 0.1px;
  font-weight: 500;
  border: none;
}
.slot__container {
  margin-top: 68px;
  position: absolute;
  top: 35%;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../../../assets/images/cardback.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  left: 50%;
  transform: translateX(-50%);
  max-width: 650px;
  width: 100%;
  background-color: white;

  border-radius: 12px;
}
.slot__outer__container {
}
.detail-container .detailed-image {
  width: 65%;
  background: url("../../../../assets/images/detail.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  color: white;
  padding: 80px !important;
}

.detail-container input[type="text"] {
  width: 90% !important;
  height: 50px !important;
  background: transparent !important;
  border: 2px solid white !important;
  color: white !important;
  outline: none !important;
  border-radius: 10px !important;
  padding: 0 10px !important;
}

.detail-container input[type="submit"] {
  /* height: 45px; */
  background: white;
  color: #0068ff !important;
  border-radius: 10px;
  border: none;
  position: relative;
  padding: 8px 20px;
  font-size: 20px;
}
.detail-container input[type="text"]::placeholder {
  color: white;
}
.co__info p {
  background: linear-gradient(to right, #5fb32a, #00853d);
  -webkit-background-clip: text;
  color: transparent;
}
@media screen and (max-width: 1150px) {
  .detail-content {
    width: 40% !important;
  }
  .detail-container .detailed-image {
    width: 60% !important;
  }
}

@media screen and (max-width: 912px) {
  .detail-content {
    width: 50% !important;
  }
  .detail-container .detailed-image {
    padding: 50px !important;
    width: 50% !important;
  }
}

@media screen and (max-width: 768px) {
  .detail-content {
    width: 100% !important;
    padding: 15% !important;
  }
  .detail-container .detailed-image {
    display: none;
  }
  .submit-btn {
    justify-content: center !important;
  }
}

@media screen and (max-width: 478px) {
  .detail-content {
    width: 100% !important;
    padding: 10% !important;
  }
  .detail-container .detailed-image {
    display: none;
    width: 40% !important;
    padding: 50px !important;
  }
}

@media screen and (max-width: 400px) {
  .detail-content {
    padding: 5% !important;
  }
  .details p {
    font-size: 12px !important;
  }
  .co__info p {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 480px) {
  .slot__container {
    margin-top: 0;
    position: absolute;
    top: 74px;
    background: linear-gradient(
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.5)
      ),
      url("../../../../assets/images/cardback.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    left: 0;
    transform: none;
    max-width: 650px;
    width: 100%;
    height: calc(100vh - 68px);
    background-color: white;

    border-radius: 0;
    padding-left: 10px;
    /* padding-right: 10px; */
  }
  .maps__desktop {
    position: relative;
    left: 350px;
  }
}
