.mission {
  height: auto;
  background-color: black;
}
.mission__m {
  min-height: 200px;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/images/mission.svg");
}
@media screen and (min-width: 450px) {
  .mission {
    min-height: 500px;
    background-size: cover;
    background-position: center;
    background-image: url("../../assets/images/mission.svg");
  }
  .mission__m {
    display: none;
  }
}
