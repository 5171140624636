.upcoming-ride-card {
  width: 400px;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.options-dropdown {
  position: absolute;
  top: 50px; /* Adjust this value as needed to position the dropdown above the ride card */
  right: 25px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1; /* Make sure the dropdown appears above other content */
}

.ride-info button {
  background: white;
  padding: 3px 13px;
  margin: 0;
  font-size: 15px;
}
.ride-info button:nth-child(2) {
  border-top: 1px solid #a1a1a177;
  border-bottom: 1px solid #a1a1a177;
  color: red;
}
.ride-info p,
span {
  font-size: 14px;
  color: #a1a1a1;
  font-weight: 500;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  /* border-radius: 10px; */
}
.icons .line {
  width: 2px;
  height: 45px;
  border-left: 1px dashed #eae4e4;
}
.icons .circleone {
  /* position: absolute; */
  height: 13px;
  width: 13px;
  background-color: #98eb57;
  border-radius: 50%;
}
.icons .borderone {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  /* height: 20px;
    width: 20px; */
  padding: 5px;
  /* border: 1px solid #98EB57; */
  background-color: rgba(151, 235, 87, 0.4);
  border-radius: 50%;
}

.icons .circletwo {
  /* position: absolute; */
  height: 13px;
  width: 13px;
  background-color: #eb5757;
  border-radius: 50%;
}
.icons .bordertwo {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  /* height: 20px;
    width: 20px; */
  padding: 5px;
  /* border: 1px solid #EB5757;
     */
  background-color: rgb(235, 87, 87, 0.4);
  border-radius: 50%;
}
.places {
  width: 100%;
}

.horizontal-line {
  width: 100%;
  height: 2px;
  background-color: #eae4e4;
  margin: 10px 0px;
}

.fare-details .fare-pickup p:nth-child(2),
.fare-details .fare p:nth-child(2) {
  background: linear-gradient(to right, #49bc92, #49bc92);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 600;
}
.fare-details .fare-pickup p:nth-child(1),
.fare-details .fare p:nth-child(1) {
  font-size: 14px;
  color: #a1a1a1;
  font-weight: 600;
}

.past-info {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.past-info p {
  font-size: 11px;
}
.about-ride p {
  font-size: 12px;
  color: #a1a1a1;
  font-weight: 400;
}

.vertical-line {
  width: 2px;
  height: 40px;
  background-color: #eae4e4;
  margin: 10px 0px;
}
p {
  margin: 0;
}
.breakdown p {
  color: #717171 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
.car p {
  color: #717171 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
.detail-header {
  background: linear-gradient(to right, #49bc92, #49bc92);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 28px;
  font-weight: 600;
  text-align: left;
  padding-left: 10px;
  padding-bottom: 15px;
}
.car-fare p:nth-child(1) {
  font-size: 14px;
  color: #a1a1a1;
  font-weight: 600;
}
.car-fare p:nth-child(2) {
  background: linear-gradient(to right, #49bc92, #49bc92);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 20px;
  font-weight: 600;
}

.instruction {
  font-size: 13px;
  color: #a1a1a1;
  text-align: left;
  margin-top: 10px;
  font-weight: 500;
}
.receipt {
  width: 100%;
  margin-top: 30px !important;
}
.receipt button {
  width: 100%;
  background: linear-gradient(to right, #49bc92, #49bc92);
  color: white;
  font-weight: 600;
}
