@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap");
* {
  font-family: "Red Hat Display", sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  outline: none;
  border: none;
  appearance: none;
  background-color: transparent;
}

.PhoneInput input {
  background-color: transparent;
  outline: none;
  border: none;
}

.PhoneInput input:focus {
  background-color: transparent;
  outline: none;
  border: none;
}

.app-store-images {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.slick-dots li button:before {
  color: white !important;
}
