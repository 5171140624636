.app {
  display: flex;
}

.sidebar {
  width: 200px;
  height: 100vh;
  /* position: sticky;
    left: 0;
    top: 0; */
  /* background-color: #f0f0f0; */
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  /* padding: 20px; */
}

button {
  display: block;
  margin-bottom: 10px;
  padding: 8px 12px;
  /* background-color: #007bff; */
  color: black;
  /* background-color: white; */
  border: none;
  /* border-radius: 5px; */
  cursor: pointer;
  transition: 0.3s;
}
button.selected {
  background: linear-gradient(to right, #49bc92, #49bc92);
  -webkit-background-clip: text;
  color: transparent;
  /* border-left: 5px solid linear-gradient(to right, #49BC92, #49BC92);
     */
  border-left: 5px solid #49bc92;
  /* border-image: linear-gradient(to right, #49BC92, #49BC92);
    border-image-slice: 2; */
  /* background-color: #0056b3; */
}

.content {
  padding: 5px;
}

@media screen and (max-width: 768px) {
  .app {
    flex-direction: column;
  }
  .sidebar {
    height: auto;
    flex-direction: row !important;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
    box-shadow: none;
  }
  .sidebar .logout {
    display: none;
  }
  .page-navigation {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: transparent;
    gap: 20px;
  }

  .status_btn {
    border: 1px solid #49bc92;
    padding: 10px;
    border-radius: 10px;
    width: 220px;
  }
  button.selected {
    background: linear-gradient(to right, #49bc92, #49bc92);
    /* -webkit-background-clip: text; */
    /* color: transparent; */
    border: none;
    color: white;
    padding: 10px;
    border-radius: 10px;
  }
}

@media screen and (max-width: 478px) {
  /* .app{
    flex-direction: column;
  }
  .sidebar{
    height: auto;
    flex-direction: row !important;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
    box-shadow: none;
  } */
  /* .sidebar .logout{
    display: none;
  } */
  .page-navigation {
    padding: 5px;
  }

  .status_btn {
    font-size: 13px;
    width: 200px;
  }
  button.selected {
    background: linear-gradient(to right, #49bc92, #49bc92);
    /* -webkit-background-clip: text; */
    /* color: transparent; */
    border: none;
    color: white;
    padding: 10px;
    border-radius: 10px;
  }
}
