.about {
  height: auto;
  background: none;
}
.about__m {
  min-height: 200px;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/images/aboutnoor.png");
}
@media screen and (min-width: 450px) {
  .about {
    min-height: 700px;
    background-size: cover;
    background-position: center;
    background-image: url("../../assets/images/aboutnoor.png");
  }
  .about__m {
    display: none;
  }
  .explore__hand {
    margin-right: 50px;
  }
}

@media screen and (max-width: 450px) {
  .aboutserviceimage {
    height: 200px;
    width: 94vw;
  }
  .explore__m {
    height: 350px;
    width: 94vw;
  }
}
