.whynoor {
  width: 90%;
  height: 300px;
  display: block;
}
.whynoor_m {
  width: 90%;

  display: none;
}
@media screen and (max-width: 450px) {
  .whynoor {
    display: none;
  }
  .whynoor_m {
    display: block;
  }
}
.paraonex,
.paratwox,
.parathreex {
  text-align: left !important;
  text-color: white !important;
}
