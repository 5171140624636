.confirm__outer {
  background-size: contain; /* Adjust as needed */
  background-repeat: no-repeat; /* Adjust as needed */
  background-position-x: center;
  min-height: 300px;
  width: 95%;
  max-width: 500px;
  background-size: 100% 180px;
}
.confirm__header {
  color: white;
}
.book {
  height: 50px;
  width: 330px;
  background: linear-gradient(to right, #49bc92, #49bc92);
  border-radius: 5px;
  color: white;
  letter-spacing: 0.1px;
  font-weight: 500;
  border: none;
}

.confirm-content {
  padding: 0 !important;
}

.confirm {
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status {
  height: 60%;
  background: white;
  padding: 0 10% !important;
}

.confirm span {
  color: #7cdc28;
}
.pickup {
  width: 100%;
  /* margin-left: 10% !important;
    margin-right: 10% !important; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #49bc92;
  border-radius: 10px;
}
.pickup .pickon {
  width: 100%;
  padding: 5px 0;
  background: #49bc92;
  border-radius: 10px 10px 0 0;
  text-align: center;
}
.pickup .pickon span {
  color: #7cdc28;
  font-size: 18px;
  font-weight: 600;
}

.pickup .picktime {
  padding: 10px 0;
}
.pickup .picktime span {
  color: #0068ff;
  font-size: 16px;
  font-weight: 500;
}

/* Styles for the custom input container */
.custom-input {
  display: flex;
  align-items: center;
  border: 1px solid #0068ff;
  border-radius: 10px;
  padding: 0 8px;
  height: 50px;
}

/* Styles for the icon */

/* Styles for the input field */
.custom-input input[type="text"] {
  border: none;
  color: #000 !important;
  outline: none;
  /* width:auto !important;  */
  height: auto !important;
  background: transparent;
  /* border: 2px solid white; */
  outline: none;
  border-radius: 10px !important;
  margin-left: 0 !important;
  padding-left: 10px;
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  /* border-radius: 10px; */
}
.icons .line {
  width: 2px;
  height: 45px;
  border-left: 1px dashed #eae4e4;
}
.icons .circleone {
  /* position: absolute; */
  height: 13px;
  width: 13px;
  background-color: #98eb57;
  border-radius: 50%;
}
.icons .borderone {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  /* height: 20px;
      width: 20px; */
  padding: 5px;
  /* border: 1px solid #98EB57; */
  background-color: rgba(151, 235, 87, 0.4);
  border-radius: 50%;
}

.icons .circletwo {
  /* position: absolute; */
  height: 13px;
  width: 13px;
  background-color: #eb5757;
  border-radius: 50%;
}
.icons .bordertwo {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  /* height: 20px;
      width: 20px; */
  padding: 5px;
  /* border: 1px solid #EB5757;
       */
  background-color: rgb(235, 87, 87, 0.4);
  border-radius: 50%;
}
.details p:nth-child(1) {
  font-size: 12px;
  font-weight: 500;
  color: #49bc92;
}
.details p:nth-child(2) {
  font-size: 13px;
  font-weight: 500;
  /* color: #49BC92; */
}

.status-text {
  color: #49bc92;
  font-size: 24px;
  text-align: center;
  margin: 25px;
}
.booking-time {
  text-align: center;
  margin-top: 10px;
  font-size: 13px;
}
.booking-time span {
  font-size: 13px;
  color: #49bc92;
}

.faredetails {
  display: flex;
  height: 40px;
  border: 1px solid #49bc92;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 18px;
}
.faredetails p {
  color: #49bc92;
  font-weight: 600;
}

@media screen and (max-width: 1300px) {
  .status-text {
    margin: 15px;
  }
  .confirm-content {
    width: 45% !important;
  }
  .confirm-image {
    width: 55% !important;
  }
  .status {
    padding: 0 5% !important;
  }
}
@media screen and (max-width: 1150px) {
  .confirm-content {
    width: 45% !important;
  }
  .confirm-image {
    width: 55% !important;
  }
  .status {
    padding: 0 5% !important;
  }
}

@media screen and (max-width: 912px) {
  .confirm-content {
    width: 50% !important;
  }
  .confirm-image {
    width: 50% !important;
  }
  .status {
    padding: 0 5% !important;
  }
  .image {
    padding: 50px !important;
  }
}

@media screen and (max-width: 768px) {
  .confirm-content {
    width: 100% !important;
  }
  .confirm-image {
    display: none;
    width: 40% !important;
  }
  .status {
    padding: 0 15% !important;
  }
  .image {
    padding: 50px !important;
  }
}

@media screen and (max-width: 478px) {
  .confirm__outer {
  }
  .confirm-content {
    width: 100% !important;
  }
  .confirm-image {
    display: none;
    width: 40% !important;
  }
  .status {
    padding: 0 10% !important;
  }
  .image {
    padding: 50px !important;
  }
  .pickup .picktime span {
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .confirm-content {
    width: 100% !important;
  }
  .status {
    padding: 0 5% !important;
  }
  .pickup .picktime {
    font-size: 12px;
  }
}
