/* Loader.css */
.classy-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height to fit your needs */
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
