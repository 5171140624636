.headerx {
  height: 100vh;
  width: 100%;
  background: url("../../assets/images/headerx.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding-left: 120px;
}
.headercontenty-head {
  width: 90%;
  color: #fff;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);

  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px; /* 140.625% */
}
.headercontenty-body {
  width: 90%;
  color: #fff;

  font-size: 33px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
}
.GoBlu {
  color: #b2d2ff;

  font-size: 33px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
}
.EV {
  color: #7cdc28;

  font-size: 33px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
}
.downloadx button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}
.whyx {
  height: 40vh;
  width: 100%;
  background: url("../../assets/images/whybg.png");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  /* font-size: 7.3vw; */
  font-size: 55px;
  font-weight: 600;
}

.ridex {
  display: flex;
  flex-direction: column;
  padding: 50px 120px;
  gap: 30px;
}
.ridex .rowonex,
.rowtwox,
.rowthreex {
  display: flex;
  justify-content: space-between;
  gap: 80px;
  align-items: center;
  margin-bottom: 60px;
}
.ridex .rowonex img,
.rowtwox img,
.rowthreex img {
  width: 500px;

  max-height: 306px;
  border-radius: 20px;
}
.ridex .rowonex p:nth-child(1),
.rowtwox p:nth-child(1),
.rowthreex p:nth-child(1) {
  background: white;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 40px;
  font-weight: 600;
}
.ridex .rowonex p:nth-child(2),
.rowtwox p:nth-child(2),
.rowthreex p:nth-child(2) {
  color: white;
  font-size: 15px;
  padding-right: 80px;
  font-weight: 400;
}
.gogreenx {
  height: 50vh;
  width: 100%;
  background: url("../../assets/images/green_bg.svg"),
    linear-gradient(
      0deg,
      #ffe57d 0%,
      #fde079 16%,
      #f6d06f 36%,
      #ebb65e 58%,
      #dc9347 82%,
      #ce7332 100%
    );
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.gogreenx .gogogo {
  color: #0e4a63;
  font-size: 30px;
  font-weight: 500;
}
.upperx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}
.gogreenx .statsx {
  width: 65%;
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  margin-top: 20px;
}
.gogreenx .statsx p {
  font-size: 60px;
  font-weight: 600;
  color: #0e4a63;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.gogreenx .statsx .gospan {
  margin-top: -10px;
  font-size: 22px;
  /* font-weight: 400; */
  color: #0e4a63;
}

.lowerx {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
  font-weight: 500;
}
.lowerx p:nth-child(1) {
  font-size: 35px;
  font-weight: 800;
  color: #0e4a63;
}
.lowerx p:nth-child(2) {
  font-size: 35px;
  font-weight: 800;
  color: #0e4a63;
}
.lowerx .lowerspan {
  font-size: 30px;
  font-weight: 800;
  color: #0e4a63;
}
.readytohit {
  height: 75vh;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 120px;
}
.readytohit .readycontentx button {
  background: linear-gradient(to right, #49bc92, #49bc92);
  color: white;
  padding: 10px 30px;
  border-radius: 10px;
  font-weight: 500;
}
.readytohit .readycontentx p:nth-child(1) {
  background: linear-gradient(to right, #49bc92, #49bc92);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 45px;
  font-weight: 600;
}
.readytohit .readycontentx p:nth-child(2) {
  /* color: #65676de8;
    font-size: 18px; */
  /* font-weight: 500; */
  /* padding-right: 200px; */
  width: 75%;
  color: #65676d;
  opacity: 0.6;

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.readytohit .readyimagex {
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.readytohit .readyimagex img {
  height: 100%;
}

.kickstartx {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../../assets/images/kickstartx.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  gap: 45px;
}
.kickstartx button {
  padding: 10px 45px;
  border-radius: 10px;
  color: #49bc92;
  font-size: 16px;
  font-weight: 500;
}
.reviewx {
  /* height: 90vh; */
  display: flex;
  padding: 35px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.reviewx p:nth-child(1) {
  background: linear-gradient(to right, #49bc92, #49bc92);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 48px;

  font-weight: 700;
}
.reviewx p:nth-child(2) {
  color: #65676d;

  font-weight: 400;
  margin-top: 10px;
  font-size: 20px;
  margin-bottom: 30px;
}

@media screen and (max-width: 1057px) {
  .ridex {
    display: flex;
    flex-direction: column;
    padding: 50px 50px;
    gap: 30px;
  }

  .readytohit {
    height: 50vh;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 80px;
  }
  .readytohit .readycontentx button {
    background: linear-gradient(to right, #49bc92, #49bc92);
    color: white;
    padding: 10px 30px;
    border-radius: 10px;
    font-weight: 500;
  }
  .readytohit .readycontentx p:nth-child(1) {
    background: linear-gradient(to right, #49bc92, #49bc92);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 30px;
    font-weight: 600;
  }
  .readytohit .readycontentx p:nth-child(2) {
    color: #65676de8;
    font-size: 18px;
    /* font-weight: 500; */
    padding-right: 50px;
  }
  .readytohit .readyimagex {
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .readytohit .readyimagex img {
    height: 100%;
  }
}
.appdownloadxy {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  position: relative;
  margin-bottom: 100px;
}

.appdownloadxy .topscannerx {
  height: 50%;
  width: 100%;
  background: url("../../assets/images/downloadbgbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.appdownloadxy .topscannerx img {
  height: 220px;
  padding-right: 130px;
}
.appdownloadxy .mobilecarouselll {
  width: 100%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -30%);
}
.appdownloadxy .bottomscannerx {
  height: 25%;
  width: 100%;
  /* background: url('../../assets/images/downloadbgbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    justify-content: end;
    align-items: center; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: -80px;
}
.appdownloadxy .bottomscannerx .downloadlinkx {
  box-shadow: 0 0 15px rgb(0, 0, 0, 0.15);
  display: flex;
  width: 65%;
  justify-content: center;
  border-radius: 10px;
  align-items: flex-start;
  padding: 20px 50px;
  gap: 20px;
}
.appdownloadxy .bottomscannerx .downloadlinkx .linkrecieve {
  width: 30%;
  background: linear-gradient(to right, #49bc92, #49bc92);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  margin-left: 10%;
}
.appdownloadxy .bottomscannerx .downloadlinkx .linkrecieve button {
  background: linear-gradient(to right, #49bc92, #49bc92);
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  border-radius: 10px;
  height: 50px;
  color: white;
  margin: 0;
  margin-top: 10px;
}
.appdownloadxy .bottomscannerx .downloadlinkx .countryx {
  color: #3d3d3d;
  border: 1px solid #0068ff;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 0 10px;
  gap: 5px;
}
.appdownloadxy .bottomscannerx .downloadlinkx input[type="text"] {
  border: 1px solid #0068ff;
  height: 50px;
  padding: 0 15px;
  border-radius: 10px;
  color: #3d3d3d;
  width: 80%;
}

.autocomplete {
  width: 100% !important;
}

.appdownloadxy .bottomscannerx .downloadlinkx input[type="text"]::placeholder {
  color: #3d3d3d;
}

@media screen and (max-width: 850px) {
  .appdownloadxy .topscannerx img {
    padding-right: 40px;
  }
  .carousel-item-bg {
    gap: 10px !important;
  }
}
@media screen and (max-width: 950px) {
  .readytohit .readyimagex img {
    height: 90%;
  }
  .ridex {
    display: flex;
    flex-direction: column;
    padding: 50px 50px;
    gap: 30px;
  }

  .ridex .rowonex img,
  .rowtwox img,
  .rowthreex img {
    width: 300px;
  }

  .ridex .rowonex p:nth-child(1),
  .rowtwox p:nth-child(1),
  .rowthreex p:nth-child(1) {
    font-size: 30px;
  }

  .ridex .rowonex,
  .rowtwox,
  .rowthreex {
    gap: 40px;
  }

  .ridex .rowonex p:nth-child(2),
  .rowtwox p:nth-child(2),
  .rowthreex p:nth-child(2) {
    color: #65676d;
    font-size: 15px;
    padding-right: 10px;
  }
  .gogreenx .statsx {
    width: 80%;
  }

  .reviewx {
    /* height: 90vh; */
    display: flex;
    padding-top: 0px;
    /* padding: 50px 0; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .reviewx p:nth-child(1) {
    text-align: center;
    font-size: 30px;
  }
  .reviewx p:nth-child(2) {
    text-align: center;
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  .readytohit {
    height: auto;
    flex-direction: column;
  }
  .readytohit .readyimagex {
    height: 100%;
    width: 100% !important;
  }
  .readytohit .readyimagex img {
    /* height: 100%; */
    width: 100% !important;
  }
  .readytohit .readycontentx {
    text-align: center;
    height: 100%;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .readytohit .readycontentx p:nth-child(2) {
    padding-right: 0px;
  }
  .headerx {
    padding-left: 0;
    justify-content: center;
    align-items: center;
  }
  .ridex {
    display: flex;
    flex-direction: column;
    padding: 50px 50px;
    gap: 30px;
  }
  .ridex .rowonex,
  .rowthreex {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
  }
  .rowtwox {
    flex-direction: column-reverse;
  }
  .ridex .rowonex .paraonex,
  .ridex .rowthreex .parathreex,
  .ridex .rowtwox .paratwox {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    text-align: left;
  }
  .appdownloadxy {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .gogreenx .statsx {
    width: 85%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lowerx p:nth-child(1) {
    text-align: center;
    font-size: 15px;
    font-weight: 800;
    color: #0e4a63;
  }
  .lowerx p:nth-child(2) {
    text-align: center;
    font-size: 15px;
    font-weight: 800;
    color: #0e4a63;
  }

  .reviewx p:nth-child(1) {
    text-align: center;
    font-size: 20px;
    padding: 0 10px;
  }
  .reviewx p:nth-child(2) {
    text-align: center;
    font-size: 12px;
    margin-bottom: 0;
  }
  .kickstartx p {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 478px) {
  .gogreenx .statsx {
    width: 85%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .gogreenx .gogogo {
    font-size: 15px;
  }
  .headerx {
    display: none;
  }
  .headery {
    display: block;
  }
  .headercontenty {
    height: 50vh;
    background: url("../../assets/images/landingmob.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
  }
  .downloady {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
  }
  .downloady .custom-input {
    width: 80%;
  }
  .downloady .custom-input input[type="text"] {
    width: 100%;
  }
  .downloadx {
    gap: 10px !important;
  }
  .downloadx button {
    width: auto;
  }
  .downloady button img {
    height: 40px;
  }
  .checkfarey {
    background: linear-gradient(to right, #49bc92, #49bc92);
    color: white;
    font-size: 18px;
    width: 80%;
    border-radius: 12px;
  }
  .readytohit {
    padding: 20px;
  }
  .swapy {
    height: 5px;
    display: flex;
    justify-content: flex-end;
    padding-right: 70px;
    align-items: center;
    z-index: 3;
    width: 90%;
  }
  .checkfareins {
    text-align: center;
    color: grey;
    margin-top: 10px;
  }
  .whyx {
    height: 30vh;
  }
  .whyx p {
    /* font-size: 40px;
        text-align: center; */
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);

    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 60.287px; /* 62.799% */
  }
  .kickstartx p {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 478px) {
  .headery {
    display: none;
  }
}

/* For screens up to 768px width */

@media screen and (max-width: 585px) {
  .lowerx p:nth-child(1),
  .lowerx p:nth-child(2) {
    font-size: 16px !important;
  }
  .lowerx .lowerspan {
    font-size: 16px !important;
  }
}
@media (max-width: 768px) {
  .gogreenx {
    height: auto;
  }
  .gogreenx .gogogo {
    text-align: center;
  }

  .gogreenx {
    height: auto;
  }
  .gogreenx .statsx p {
    font-size: 34px;
    text-align: center !important;
    margin-bottom: 25px;
  }

  .gogreenx .statsx .gospan {
    font-size: 12px;
  }

  .lowerx p:nth-child(1),
  .lowerx p:nth-child(2) {
    width: 80%;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .lowerx .lowerspan {
    font-size: 16px;
  }
}

/* For screens between 769px and 1024px width */
@media (min-width: 769px) and (max-width: 1024px) {
  .gogreenx .statsx p {
    font-size: 50px;
  }

  .gogreenx .statsx .gospan {
    font-size: 20px;
  }

  .lowerx p:nth-child(1),
  .lowerx p:nth-child(2) {
    font-size: 22px;
  }

  .lowerx .lowerspan {
    font-size: 22px;
  }
}

/* For screens above 1024px width */
@media (min-width: 1025px) {
  .gogreenx .statsx p {
    font-size: 60px;
  }

  .gogreenx .statsx .gospan {
    font-size: 22px;
  }

  .lowerx p:nth-child(1),
  .lowerx p:nth-child(2) {
    font-size: 30px;
  }

  .lowerx .lowerspan {
    font-size: 30px;
  }
}

@media screen and (max-width: 400px) {
  .swapy {
    padding-right: 40px;
  }
}
