.co2__saved__total p {
  color: black !important;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
a {
  color: black !important;
  /* color: #FF; */

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.787px; /* 129.918% */
}
p {
  font-weight: 600;
}
.navbar-brand {
  /* margin-left: -60px; */
}
.navbar-nav {
  /* margin-right: -40px; */
}

@media only screen and (max-width: 990px) {
  .navbar-nav {
    margin-right: 0;
  }
  .navbar-brand {
    margin-left: 0;
  }
}

@media only screen and (max-width: 1000px) {
  .co2__saved__total {
    display: none !important;
  }
}

.navbar-toggler:focus {
  outline: none !important;
  border: none !important;
}
