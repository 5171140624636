.cardimage img
{
    margin-left: 5%;
    text-align: center;
    width: 90%;
    border-radius: 16px;
    /* border: 1px solid black; */
    margin-bottom: 20px;
    margin-top: 20px;
}
.cardtext
{
    margin-left: 5%;
    width: 90%;
    color: #79797A;
    /* font-family: Red Hat Display; */
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
    margin-bottom: 20px;
}

@media screen and (max-width:1400px){
 
    .cardimage img
    {
        height: 190px;
        margin-left: 5%;
        text-align: center;
        width: 90%;
        border-radius: 16px;
        /* border: 1px solid black; */
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .cardtext
    {
        margin-left: 5%;
        width: 90%;
        color: #79797A;
        /* font-family: Red Hat Display; */
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 140% */
        margin-bottom: 20px;
    }

}
@media screen and (max-width:1040px){
 
    .cardimage img
    {
        height: 170px;
    }
    .cardtext
    {
       
        font-size: 13px;
    }

}
@media screen and (max-width:860px){
 
    .cardimage img
    {
        height: 150px;
    }
    .cardtext
    {
       
        font-size: 12px;
    }

}
@media screen and (max-width:741px){
 
    .cardimage img
    {
        height: 150px;
    }
    .cardtext
    {
       
        font-size: 12px;
    }

}
@media screen and (max-width:687px){
 
    .cardimage img
    {
        height: 150px;
    }
    .cardtext
    {
       line-height: 20px;
        font-size: 13px;
    }

}
@media screen and (max-width:549px){
 
    .cardimage img
    {
        height: 140px;
    }
    .cardtext
    {
       line-height: 20px;
        font-size: 12px;
    }

}