:root {
  --size: 100px;
}

.footer-with-bg {
  background-color: black;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 100px;
  color: white;
}

.footer-with-bg a {
  text-decoration: none;
  color: white;
  font-size: 17px;
}
.maincontentx {
  gap: var(--size);
}
.footercontentxone {
  gap: var(--size);
}
.footercontenttwox {
  gap: var(--size);
}

.Footer-Head {
  color: #fff;
  /* text-align: center; */

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32.605px; /* 163.026% */
}
.list-unstyled li a {
  /* padding-bottom: 10px; */
  color: #fff;

  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 16px */
}
@media screen and (max-width: 1300px) {
  :root {
    --size: 80px;
  }
}
@media screen and (max-width: 1200px) {
  .footer-with-bg {
    padding: 50px 50px;
  }
}
@media screen and (max-width: 1100px) {
  .contentcontetxad {
    flex-direction: column;
    gap: 10px;
  }
}
@media screen and (max-width: 900px) {
  .maincontentx {
    flex-direction: column;
    gap: 5px;
  }
  .footercontentxone {
    /* flex-direction: column; */
    gap: 215px;
  }
  .footercontenttwox {
    /* flex-direction: column; */
    gap: 200px;
  }
}
@media screen and (max-width: 610px) {
  .footer-with-bg {
    padding: 50px 30px;
  }
  .footercontentxone {
    /* flex-direction: column; */
    gap: 95px;
  }
  .footercontenttwox {
    /* flex-direction: column; */
    gap: 80px;
  }
}
@media screen and (max-width: 500px) {
  .footercontentxone {
    /* flex-direction: column; */
    gap: 55px;
  }
  .footercontenttwox {
    /* flex-direction: column; */
    gap: 65px;
  }
  .footer-with-bg a {
    font-size: 10px;
    font-weight: 300;
  }
}
@media screen and (max-width: 414px) {
  .footercontentxone {
    /* flex-direction: column; */
    gap: 30px;
  }
  .footercontenttwox {
    /* flex-direction: column; */
    gap: 38px;
  }
}
@media screen and (max-width: 350px) {
  .footercontentxone {
    flex-direction: column;
    gap: 0px;
  }
  .footercontenttwox {
    flex-direction: column;
    gap: 0px;
  }
}

footer a {
  color: white !important;
}
