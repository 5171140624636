.mobile {
  display: none;
}
.headera {
  height: 100vh;
  width: 100%;
  background: url("../../assets/images/headerx.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  /* padding-left: 120px; */
}
.headeraa {
  height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 200%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.bodya {
  margin-bottom: 10vh;
  width: 60%;
  color: #fff;
  text-align: center;
  /* font-family: Red Hat Display; */
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: 126.856px; /* 132.142% */
  letter-spacing: 0.96px;
}
.whoarewe {
  height: 90vh;
  display: flex;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.whoareweimg {
  width: 50%;
  /* border: 1px solid black; */
  text-align: center;
}
.whoareweimg img {
  width: 80%;
  /* border: 1px solid black; */
  border-radius: 20px;
}
.whoarewebody {
  margin-left: 2%;
  width: 40%;
  /* border: 1px solid black; */
}
.whoarewehead {
  color: var(--black-100, #131313);
  /* text-align: center; */
  /* font-family: Red Hat Display; */
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px; /* 317.141% */
}
.whoareweheadbody {
  margin-top: 20px;
  color: var(--black-90, #2b2b2b);
  /* font-family: Red Hat Display; */
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 158.5%; /* 38.04px */
}
.theyloveus {
  height: 75vh;
  background: var(
    --blue,
    linear-gradient(97deg, #49bc92 -19.14%, #49bc92 107.82%)
  );
  display: flex;
}
.theyloveustext {
  width: 30%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 10vh;
  margin-bottom: 10vh;
  /* border: 1px solid black; */
}
.google1 {
  display: inline-flex;
  padding: 7px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7.778px;
  border-radius: 12.444px;
  background: #fff;
  margin-bottom: 5px;
}
.fivestarx1 {
  display: flex;
  width: 50%;
  justify-content: space-between;
}
.fivestarx1 .bi {
  font-size: 36px;
  color: #ffa902;
}
.theyloveustext1 {
  color: var(--white, #fcfcfc);
  /* text-align: center; */
  /* font-family: Red Hat Display; */
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 126.856px; /* 264.284% */
}
.theyloveustext2 {
  color: var(--white, #fcfcfc);
  /* font-family: Red Hat Display; */
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 51.856px; /* 144.045% */
}
.reviews {
  display: flex;
  align-items: center;
}
.ourstory {
  height: 245vh;
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
}
.ourstoryhead {
  /* font-family: Red Hat Display; */
  text-align: center;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 76.8px */
  background: var(
    --blue,
    linear-gradient(97deg, #49bc92 -19.14%, #49bc92 107.82%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 15vh;
}
.ourstorytitle {
  color: var(--black-50, #888);
  /* font-family: Red Hat Display; */
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  text-align: center;
  margin-top: 2vh;
  margin-bottom: 25vh;
}

@media screen and (max-width: 1400px) {
  .bodya {
    font-size: 55px;
    line-height: 80px; /* 132.142% */
  }
  .whoareweimg img {
    height: 400px;
  }
  .whoarewehead {
    font-size: 35px;
    line-height: 70px; /* 317.141% */
  }
  .whoareweheadbody {
    font-size: 19px;
  }
  .google1 {
    padding: 6px 8px;
    gap: 6px;
  }
  .fivestarx1 .bi {
    font-size: 30px;
    color: #ffa902;
  }
  .theyloveustext1 {
    font-size: 40px;
    line-height: 100px; /* 264.284% */
  }
  .theyloveustext2 {
    font-size: 26px;
    line-height: 45px; /* 144.045% */
  }
  .reviews img {
    height: 400px;
  }
  .ourstoryhead {
    font-size: 55px;
  }
  .ourstorytitle {
    font-size: 20px;
  }
}
@media screen and (max-width: 1040px) {
  .bodya {
    font-size: 55px;
    line-height: 80px; /* 132.142% */
  }
  .whoareweimg img {
    height: 370px;
  }
  .whoarewe {
    height: 80vh;
  }
  .whoarewehead {
    /* font-size: 35px; */
    line-height: 65px; /* 317.141% */
  }
  .whoareweheadbody {
    font-size: 16px;
  }
  .ourstoryhead {
    font-size: 50px;
  }
  .ourstorytitle {
    font-size: 18px;
  }
}
@media screen and (max-width: 934px) {
  .bodya {
    font-size: 45px;
    line-height: 80px; /* 132.142% */
  }
  .whoareweimg img {
    height: 360px;
  }
  .google1 {
    padding: 6px 8px;
    gap: 6px;
  }
  .fivestarx1 .bi {
    font-size: 25px;
    color: #ffa902;
  }
  .theyloveustext1 {
    font-size: 30px;
    line-height: 100px; /* 264.284% */
  }
  .theyloveustext2 {
    font-size: 20px;
    line-height: 45px; /* 144.045% */
  }
  .reviews img {
    height: 350px;
  }
}
@media screen and (max-width: 760px) {
  .bodya {
    font-size: 40px;
    line-height: 80px; /* 132.142% */
  }
  .whoareweimg img {
    height: 330px;
  }
  .whoarewehead {
    font-size: 30px;
    line-height: 50px; /* 317.141% */
  }
  .whoareweheadbody {
    font-size: 13px;
  }
  .google1 {
    padding: 3px 4px;
    gap: 6px;
  }
  .google1 img {
    height: 30px;
  }
  .fivestarx1 .bi {
    font-size: 19px;
    color: #ffa902;
  }
  .theyloveus {
    height: 70vh;
  }
  .theyloveustext1 {
    font-size: 24px;
    line-height: 100px; /* 264.284% */
  }
  .theyloveustext2 {
    font-size: 16px;
    line-height: 45px; /* 144.045% */
  }
  .reviews img {
    height: 300px;
  }
  .ourstory {
    height: 220vh;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}
@media screen and (max-width: 687px) {
  .bodya {
    font-size: 30px;
    line-height: 50px; /* 132.142% */
  }
  .whoarewe {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .whoareweimg img {
    height: 330px;
  }
  .whoarewehead {
    font-size: 30px;
    line-height: 50px; /* 317.141% */
  }
  .whoareweheadbody {
    font-size: 13px;
  }
  .google1 {
    padding: 3px 4px;
    gap: 6px;
  }
  .google1 img {
    height: 30px;
  }
  .fivestarx1 .bi {
    font-size: 19px;
    color: #ffa902;
  }
  .theyloveus {
    height: 70vh;
  }
  .theyloveustext1 {
    font-size: 20px;
    line-height: 100px; /* 264.284% */
  }
  .theyloveustext2 {
    font-size: 16px;
    line-height: 45px; /* 144.045% */
  }
  .reviews img {
    height: 280px;
  }
  .ourstory {
    height: 220vh;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}
@media screen and (max-width: 573px) {
  .bodya {
    font-size: 28px;
    line-height: 50px; /* 132.142% */
  }
  .whoareweimg img {
    height: 300px;
  }
  .whoarewehead {
    font-size: 25px;
    line-height: 30px; /* 317.141% */
  }
  .whoareweheadbody {
    font-size: 12px;
  }
  .google1 {
    padding: 3px 4px;
    gap: 6px;
  }
  .google1 img {
    height: 30px;
  }
  .fivestarx1 .bi {
    font-size: 19px;
    color: #ffa902;
  }
  .theyloveus {
    height: 50vh;
  }
  .theyloveustext1 {
    font-size: 15px;
    line-height: 100px; /* 264.284% */
  }
  .theyloveustext2 {
    font-size: 10px;
    line-height: 30px; /* 144.045% */
  }
  .reviews img {
    height: 200px;
  }
  .ourstory {
    height: 220vh;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}
@media screen and (max-width: 473px) {
  .laptop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

.finding-inspiration {
  height: 40vh;
  background: var(
    --blue,
    linear-gradient(97deg, #49bc92 -19.14%, #49bc92 107.82%)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.inspiration-text {
  width: 80%;
  color: #fff;
  /* text-align: center; */
  /* font-family: Red Hat Display; */
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.035px; /* 137.648% */
  letter-spacing: 0.24px;
  margin-top: 10vh;
}
.about-details {
  margin-top: 4vh;
  margin-bottom: 7vh;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  /* border: 1px solid black; */
}

.details-car {
  height: 45vh;
  width: 100%;
  background: url("../../assets/images/about_2.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.details-car-back {
  width: 100%;
  height: 45vh;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0.29%, #000 92.27%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.details-car-text {
  width: 90%;
  color: #fff;
  text-align: center;
  /* font-family: Red Hat Display; */
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 144%; /* 14.4px */
  margin-bottom: 1vh;
}

.star-google {
  height: 30vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16.069px;
  background: #eff4f9;
  margin-bottom: 7vh;
}
.fivestarx2 {
  display: flex;
  width: 55%;
  justify-content: space-between;
}
.fivestarx2 .bi {
  font-size: 36px;
  color: #ffa902;
}
.rating-google {
  width: 80%;
  text-align: center;
  /* font-family: Red Hat Display; */
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  background: var(--grad, linear-gradient(97deg, #557ed6 0%, #17326a 107.81%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.our-story {
  /* height: 100vh; */
  /* border: 1px solid black; */
  border-radius: 10.558px;
  background: #eff4f9;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.our-story-body {
  /* height: 50vh; */
  width: 90%;
  /* margin-left: 5%;
    margin-right: 5%; */
  /* border: 1px solid black; */
  margin-top: 17px;
}
.our-story-head {
  /* text-align: center; */
  /* font-family: Red Hat Display; */
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  background: var(--grad, linear-gradient(97deg, #557ed6 0%, #17326a 107.81%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 13px;
}
.our-story-text {
  color: var(--black-100, #131313);
  /* font-family: Red Hat Display; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 156%; /* 15.6px */
  margin-bottom: 25px;
}

.our-story-img {
  height: 40vh;
  width: 100%;
  background: url("../../assets/images/about_1.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0 0 10.558px 10.558px;
}

@media screen and (max-width: 410px) {
  .inspiration-text {
    font-size: 25px;
  }
  .details-car-text {
    font-size: 12px;
    margin-bottom: 2vh;
  }
  .fivestarx2 .bi {
    font-size: 30px;
    color: #ffa902;
  }
  .rating-google {
    font-size: 23px;
  }
  .our-story-head {
    font-size: 20px;
  }
  .our-story-text {
    font-size: 11px;
  }
  .our-story-img {
    height: 35vh;
  }
}
@media screen and (max-width: 378px) {
  .inspiration-text {
    font-size: 24px;
  }
  .details-car-text {
    font-size: 11px;
    margin-bottom: 2vh;
  }
  .fivestarx2 .bi {
    font-size: 27px;
    color: #ffa902;
  }
  .rating-google {
    font-size: 20px;
  }
  .our-story-head {
    font-size: 18px;
  }
  .our-story-text {
    font-size: 10px;
  }
  .our-story-img {
    height: 30vh;
  }
}
@media screen and (max-width: 329px) {
  .inspiration-text {
    font-size: 23px;
  }
  .details-car-text {
    font-size: 10px;
    margin-bottom: 2vh;
  }
  .fivestarx2 .bi {
    font-size: 25px;
    color: #ffa902;
  }
  .rating-google {
    font-size: 18px;
  }
  .our-story-head {
    font-size: 17px;
  }
  .our-story-text {
    font-size: 9px;
  }
  .our-story-img {
    height: 28vh;
  }
}
