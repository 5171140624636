/* Custom CSS */
.custom-button::before,
.custom-button.collapsed::before {
  display: none !important; /* Hide the default arrow icons */
}

.custom-button::after {
  content: "+"; /* Plus sign for collapsed state */
  float: right;
  font-size: 1.25rem;
  transition: transform 0.2s;
}

.custom-button.collapsed::after {
  content: "-"; /* Minus sign for expanded state */
  transform: rotate(180deg);
}
.header {
  background: linear-gradient(155deg, #49bc92, #49bc92);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 120px 80px 50px 80px;
}
.form-container {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  /* background-color: blue; */
  /* background: linear-gradient(155deg, #49BC92, #49BC92); */

  /* padding: 120px 80px 0 80px; */
}
.content-container {
  padding: 0 100px;
}
.form-container .content {
  width: 80%;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px 30px;
  margin-top: -120px;
  background: white;
  box-shadow: 0 0 5px 2px #5e5e5e67;
}
/* .form-container .content form{
    padding: 20px 30px;
} */
.form-container h4 {
  background: linear-gradient(155deg, #49bc92, #49bc92);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 17px;
  text-align: left !important;
}
.form-group {
  margin-bottom: 15px;
}
.form-group label {
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 2px;
}

.faq-container {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  /* background-color: green; */
}

.faq-container .faq {
  width: 80%;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px 30px;
  margin-top: -120px;
  background: white;
  box-shadow: 0 0 5px 2px #5e5e5e67;
}

button[type="submit"] {
  background: linear-gradient(to right, #49bc92, #49bc92);
  color: white;
  padding: 8px 35px;
}

.info {
  width: 80%;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px 30px;
  background: white;
  box-shadow: 0 0 5px 2px #5e5e5e67;
}
.info h4 {
  color: black;
}
.info ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.info ul li {
  background: linear-gradient(to right, #49bc92, #49bc92);
  -webkit-background-clip: text;
  color: transparent;
  padding-bottom: 5px;
}
.info ul li > .bi {
  padding-right: 10px;
  background: linear-gradient(to right, #49bc92, #49bc92);
  -webkit-background-clip: text;
  color: transparent;
}

@media screen and (max-width: 1100px) {
  /* .header{
        padding: 120px 40px 50px 40px;
    } */
  .content-container {
    padding: 0 80px;
  }
  .faq-container .faq {
    width: 90%;
  }
  .form-container .content {
    width: 90%;
  }
  .info {
    width: 90%;
  }
}
@media screen and (max-width: 900px) {
  /* .header{
        padding: 120px 40px 50px 40px;
    } */
  .content-container {
    padding: 0 50px;
  }
  .faq-container .faq {
    width: 95%;
  }
  .form-container .content {
    width: 95%;
  }
  .info {
    width: 95%;
  }
}

@media screen and (max-width: 800px) {
  .content-container {
    gap: 30px;
  }
  .form-container {
    width: 100%;
    justify-content: center;
  }
  .faq-container {
    width: 100%;
    justify-content: center;
  }
  .content-container {
    padding: 0 30px;
  }
  .faq-container .faq {
    width: 100%;
  }
  .form-container .content {
    width: 100%;
  }
  .info {
    width: 100%;
  }
}
@media screen and (max-width: 670px) {
  .header {
    padding: 120px 40px 50px 40px;
  }

  .content-container {
    flex-direction: column;
    align-items: center;
    padding: 0 80px;
  }
  .form-container {
    width: 100%;
    justify-content: center;
  }
  .faq-container {
    /* margin-top: 0; */
    width: 100%;
    justify-content: center;
  }
  .faq-container .faq {
    margin-top: 0;
    width: 100%;
  }
  .form-container .content {
    width: 100%;
  }
  .info {
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .header {
    padding: 120px 80px 50px 80px;
    flex-direction: column;
  }

  .content-container {
    flex-direction: column;
    align-items: center;
    padding: 0 50px;
  }
  .form-container {
    width: 100%;
    justify-content: center;
  }
  .faq-container {
    /* margin-top: 0; */
    width: 100%;
    justify-content: center;
  }
  .faq-container .faq {
    margin-top: 0;
    width: 100%;
  }
  .form-container .content {
    width: 100%;
  }
  .info {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .header {
    padding: 120px 40px 50px 40px;
    flex-direction: column;
  }

  .content-container {
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
  }
  .form-container {
    width: 100%;
    justify-content: center;
  }
  .faq-container {
    /* margin-top: 0; */
    width: 100%;
    justify-content: center;
  }
  .faq-container .faq {
    margin-top: 0;
    width: 100%;
  }
  .form-container .content {
    width: 100%;
  }
  .info {
    width: 100%;
  }
}
