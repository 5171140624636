.places input[type="text"] {
  background: transparent;
  border: none;
  outline: none;
  width: 100% !important;
}

.newcardprop {
  width: 400px;
  height: 400px;
  /* background-color: #fff; */
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.newcardprop {
  background: url("../../assets/images/cardback.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* backdrop-filter: opacity(0.1); */
}
.horizontalline {
  width: 100%;
  height: 2px;
  background: #d9d9d9;
  margin: 10px 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  align-items: center;
}
.goblu-ev {
  font-size: 40px;
  font-weight: 600;
}
.goblu {
  font-size: 40px;
  font-weight: 600;
  color: #49bc92;
}
.ev {
  font-size: 40px;
  font-weight: 600;
  color: #7cdc28;
}
.greendot {
  height: 15px;
  width: 15px;
  background-color: #98eb57;
  border-radius: 50%;
}
.reddot {
  height: 15px;
  width: 15px;
  background-color: #eb5757;
  border-radius: 50%;
}
.check-fare {
  height: 50px;
  width: 100%;
  background: linear-gradient(to right, #49bc92, #49bc92);
  border-radius: 10px;
  color: white;
  font-weight: 500;
}
.offer {
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  color: #3d3d3d;
}
.discountx {
  margin-bottom: -43px;
  font-size: 15px;
  /* height: 50px; */
  border-radius: 15px;
  text-align: center;
  background-color: #68c716;
  color: white;
  /* z-index: 999; */
  padding: 10px 30px;
  font-weight: 600;
  overflow: visible;
}
.starx {
  margin-top: -35px;
  font-size: 12px;
  /* height: 50px; */
  border-radius: 10px;
  background: linear-gradient(92deg, #49bc92 0%, #49bc92 100%);
  text-align: center;
  color: white;
  /* z-index: 999; */
  padding: 3px 15px;
  font-weight: 600;
  overflow: visible;
}
.fivestarx {
  background: linear-gradient(to right, #49bc92, #49bc92);
  color: white;
  font-size: 12.47px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  /* padding: 2px 12px; */
  border-radius: 10px;
  /* margin-top: -34px;
    margin-bottom: 20px; */
}
.fivestarx .bi {
  font-size: 15px;
  color: #ffd66b;
}
