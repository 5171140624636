.service_image_3 {
}

@media screen and (max-width: 450px) {
  .service_image_3 {
    width: 95vw;
  }
  .everykm_img {
    height: 350px;
    width: 350px;
    scale: none !important;
    left: -10px;
  }
  .everykm__text {
    /* font-size: 35px !important; */
    margin-top: 10px;
  }
  .hero__mobile {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
  }
  .container__1 .container__2 {
    padding: 15px;
  }
  .hero__outer {
    max-width: 90%;
    left: 50%;
    transform: translatex(5%);
  }
}
@media screen and (min-width: 450px) {
  .hero__outer {
    position: relative;
    left: 50%;
    transform: translatex(-50%);
  }
  .hero__outer .container__1 {
    padding-left: 65px;
  }
}
